import { Outlet, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { PageWrapper, MainLogoWrapper, Footer, FoxWrapper, SocialMediaWrapper, TandC, Legal } from "./styled";
import FoxBlack from '../assets/images/fox_black.png';
import FBIcon from '../assets/images/fb_icon.png';
import IGIcon from '../assets/images/ig_icon.png';
import PinIcon from '../assets/images/pinterest_icon.png';
import XIcon from '../assets/images/x_icon.png';
import LargeTonightLogo from '../assets/images/Logo-Up.png';


const BaseLayout = () =>{

    const location = useLocation();
    const [removeMargin, setRemoveMargin] = useState(false);

    useEffect(() => {
        const pathsToRemoveMargin = ['/error', '/form', '/thanks'];
        if (pathsToRemoveMargin.includes(location.pathname)) {
          setRemoveMargin(true);
        } else {
          setRemoveMargin(false);
        }
      }, [location]);

    return(
        <PageWrapper>
        
        <MainLogoWrapper>
            <img src={LargeTonightLogo} alt="Fox's Universal Basic Guys"/>
        </MainLogoWrapper>
        <Outlet/>
        <Footer nomargin={removeMargin}>
            <FoxWrapper>
                <a href='https://fox.com' target='_blank' rel="noreferrer"><img src={FoxBlack} alt="Fox"/></a>
            </FoxWrapper>
            <SocialMediaWrapper>
                <a href='https://www.facebook.com/FOXTV' target='_blank' rel="noreferrer"><img src={FBIcon} alt='Facebook'/></a>
                <a href='https://twitter.com/FOXTV' target='_blank' rel="noreferrer"><img src={XIcon} alt='X'/></a>
                <a href='https://instagram.com/foxtv' target='_blank' rel="noreferrer"><img src={IGIcon} alt='Instagram'/></a>
                <a href='https://www.pinterest.com/foxbroadcasting' target='_blank' rel="noreferrer"><img src={PinIcon} alt='Pinterest'/></a>
            </SocialMediaWrapper>
            <TandC>
               <a href='https://rules.creativezing.com/ubgiw/' target='_blank' rel="noreferrer">Terms & Conditions</a>
               <p>All rights reserved © Fox 2024</p>
            </TandC>
            <Legal>
            <p>Participation Period is on 9/29/24 from 11:00 am ET until 3:00 pm ET, or when all prizes have been awarded. Open to participants who are 13+ years of age and in attendance at Midtown Tampa during the Participation Period. Minors must have parent/legal guardian permission. All prizing to be awarded in the form of a digital gift card. See Official Rules at https://bit.ly/UBGuy. Void where prohibited. Sponsor: Fox Broadcasting Company, LLC</p>
            </Legal>
        </Footer>
        </PageWrapper>
    )
}

export default BaseLayout;